
export function UserFilter({ username, setUsername,  role, setRole, email, setEmail }) {
    return (
      <>
        <div className="form-group m-3">
          <label htmlFor="arg">Usuario:</label>
          <input className='form-control' type="text" id="arg" value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div className="form-group m-3">
          <label htmlFor="arg">ROL:</label>
          <select className='form-control'
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              >
                <option value="">Seleccionar</option>
                <option value="1">Administrador</option>
                <option value="2">Usuario</option>
                <option value="3">Dispositivo</option>
              </select>
        </div>
        <div className="form-group m-3">
          <label htmlFor="group">e-mail:</label>
          <input className='form-control' type="text" id="group" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
      </>
      );
  }
  