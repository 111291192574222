import { useAuthContext } from '../../context/authContext';
import { setData } from '../../utilities/set-data-user';
import { TryCreateUser } from '../../services/Service';
import { ModalConstruct } from '../Dashboard/Modal';
import { USER } from '../../consts/constants';
import React, { useState } from 'react';

function CreateUser ({ onUserCreated }){
  // control del estado del modal
  const [showModal, setShowModal] = useState(false);
  
  const search = USER;
  const { isAuth } = useAuthContext();
  const [role, setRole] = useState('');
  const [user, setUser] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    // construye el formato del json que envía los datos de creación del usuario
    const data = setData({role, user, email, password});
    // envía la solicitud http para creación de usuario
    await TryCreateUser({isAuth, search, data});
   // oculta el modal una vez realizada la tarea
   setShowModal(false);
   
   onUserCreated();
  };

  return (
    <ModalConstruct  modalTitle={'Crear Usuario'} buttonTitle={'Crear Usuario'} showModal={showModal} setShowModal={setShowModal}>
      <form onSubmit={handleSubmit}>
        <div className="User-form-content">
          <div className="form-group mt-3">
              <label htmlFor="user">Usuario:</label>
              <input className='form-control'
              type="text"
              id="user"
              value={user}
              onChange={(e) => setUser(e.target.value)}
              />
          </div>
          <div className="form-group mt-3">
              <label htmlFor="role">Rol:</label>
              <select className='form-control'
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              >
                <option value="">Seleccionar</option>
                <option value="1">Administrador</option>
                <option value="2">Usuario</option>
                <option value="3">Dispositivo</option>
              </select>
          </div>
          {(role === '1' || role === '2') && (
              <div className="form-group mt-3">
                <label htmlFor="email">Email (Rol 1 - 2):</label>
                <input className='form-control'
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
              </div>
          )}
          {role === '3' && (
              <div className="form-group mt-3">
                <label htmlFor="password">Contraseña (Rol 3):</label>
                <input className='form-control'
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
              </div>
          )}
          <div className="d-grid gap-2 mt-5 mb-3">
            <button className="btn btn-submit" type="submit">Enviar</button>
          </div>
        </div>
      </form>
    </ModalConstruct>
  );
};


export default CreateUser;