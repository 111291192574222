import { Routes, Route, HashRouter } from 'react-router-dom';
import { PrivateRoutes, PublicRoutes } from './config/routes';
import { AuthContextProvider } from './context/authContext';
import PrivateRoute from './components/router/PrivateRoute';
import PublicRoute from './components/router/PublicRoute';
import Private from './pages/Private/Private';
import { SnackbarProvider } from 'notistack';
import Login from './pages/Login/Login';
import './App.css';

function App() {
  return (
    <div className="App">
        <AuthContextProvider>
          <SnackbarProvider>
              <HashRouter>
                <Routes>
                  <Route exact path='/' element={<PublicRoute />}>
                    <Route index element={<Login />} />
                    <Route path={PublicRoutes.LOGIN} element={<Login />} />
                  </Route>
                  <Route path={PrivateRoutes.PRIVATE} element={<PrivateRoute />} >
                    <Route index element={<Private />} />
                  </Route>
                </Routes>
              </HashRouter>
          </SnackbarProvider>
        </AuthContextProvider>
    </div>
  );
}

export default App;
