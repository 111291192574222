import { arrayKey, arrayTitle, route } from '../../utilities/set-arrays-container';
import { TryDeleteUser, TrySearch, TrySearchBoxes } from '../../services/Service';
import { DEVI, EVEN, BOXS, TRAN, USER } from '../../consts/constants';
import { setStateDevice } from '../../utilities/set-state-device';
import { CircularProgress, Pagination } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { getRoleUser } from '../../utilities/get-role-user';
import { useAuthContext } from '../../context/authContext';
import { CaretDownIcon } from '../../icons/caret-down';
import { CaretUpIcon } from '../../icons/caret-up';
import { TrashIcon } from '../../icons/trash';
import { ModalConstruct } from './Modal';
import moment from 'moment';

const dataKey = {
  [DEVI]: 'devices',
  [EVEN]: 'events',
  [TRAN]: 'transactions',
  [USER]: 'users',
  [BOXS]: 'boxes',
};

function useDataFetch({ container, onFilter, onSelectedDevice, sortConfig, setSortConfig }) {
  const { isAuth } = useAuthContext();
  const [pages, setPages] = useState(1);
  const [blocks, setBlock] = useState([]);
  const [devName, setDevName] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const memoizedDataKey = useMemo(() => dataKey, []);
  
  const search = route(container);
  
  useEffect(() => {
    const crypts = arrayKey(container);
    if (container !== 'DISPOSITIVO') {
      setBlock([]);
      setLoading(true);
      const fetchData = async () => {
        try {
          const newOnFilter = { ...onFilter };
          newOnFilter.order = sortConfig.order;
          newOnFilter.sort = crypts[sortConfig.sort];
          const data = await TrySearch({ isAuth, search, onFilter: newOnFilter });
          setBlock(data[memoizedDataKey[search]]);
          setPages(data.num_pages);
          setCurrentPage(1);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [ container, isAuth, search, onFilter, memoizedDataKey, setSortConfig, devName, sortConfig.order, sortConfig.sort ]);
  
  
  const handleSort = async (sort) => {
    const order = (sortConfig && sortConfig.sort === sort && sortConfig.order === 'asc') ? 'desc' : 'asc';
    await setSortConfig({ sort, order });
  };
  
  const handleDelete = async (username) => {
    try {
      await TryDeleteUser(username);
      const data = await TrySearch({ isAuth, search, onFilter });
      setBlock(data[memoizedDataKey[search]]);
      setPages(data.num_pages);
    } catch (error) {}
  };
  
  const handlePageChange = async (event, newPage) => {
    setCurrentPage(newPage);
    try {
      const newOnFilter = { ...onFilter };
      newOnFilter.page = newPage;
      const data = await TrySearch({ isAuth, search, onFilter: newOnFilter });
      setBlock(data[memoizedDataKey[search]]);
      setPages(data.num_pages);
    } catch (error) {}
  };
  
  const handleSelectDevice = async (deviceid) => {
    try {
      const data = await TrySearchBoxes({deviceid});
      setBlock(data[memoizedDataKey['/boxes']]);
      setPages(data.num_pages);
      onSelectedDevice(deviceid);
      setDevName(deviceid);
    } catch (error) {}
  }
  return {
    pages,
    blocks,
    loading,
    handleSort,
    currentPage,
    handleDelete,
    handlePageChange,
    handleSelectDevice,
  };
}

export default function RenderContainer({ container, onFilter, onSelectedDevice }) {
  
  const crypts = arrayKey(container);
  const title = arrayTitle(container);
  // eslint-disable-next-line
  const [index, setIndex] = useState('');
  const [username, setUsername] = useState('');
  const [showModal, setShowModal] = useState(false);
  // inicialización del estado de setSortConfig 
  const initialSortConfig = useMemo(() => ({ sort: null, order: null }), []);
  const [sortConfig, setSortConfig] = useState(initialSortConfig);
  
  const {
    pages,
    blocks,
    loading,
    handleSort,
    currentPage,
    handleDelete,
    handlePageChange,
    handleSelectDevice,
  } = useDataFetch({ container, onFilter, sortConfig, setSortConfig, onSelectedDevice });
  
  // Restablecer el sort cuando cambia el container
  useEffect(() => {
    setSortConfig(initialSortConfig);
  }, [container, initialSortConfig]);
  
  const handleShowModal = (index, username) => {
    setIndex(index);
    setUsername(username);
  };

  return (
    <div className='justify-content-center'>
        <div className='justify-content-center'>
          <div className='table-responsive'>
            <table className='table table-striped table-sm'>
              <thead>
                <tr>
                  {
                    title.map((content, index) => (
                      <th key={index} className='' scope='col'>
                        { content }
                        {
                          (container !=='DISPOSITIVO') &&
                          (<button 
                            id={content === '' ? 'borrador' : index}
                            className='btn btn-sort ms-1'
                            onClick={() => handleSort(index)}
                            >
                            {sortConfig.sort === index && (sortConfig.order === 'desc' ? <CaretUpIcon /> : <CaretDownIcon />)}
                          </button>)
                        }
                      </th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                { loading ? (
                  <tr>
                    <td className="circular-progress">
                      <span className="circular-progress">
                        <CircularProgress />
                      </span>
                    </td>
                  </tr>
                ) : (
                  blocks.map((block, index) => (
                    <tr key={index}>
                    {crypts.map((crypt, index1) => (
                      <td key={index1} className=''>
                        {crypt === 'date' || crypt === 'date_delete' || crypt === 'creation_date'
                          ? block[crypt] && moment(block[crypt]).format('DD-MM-YYYY H:mm:ss')
                          : crypt === 'role'
                          ? getRoleUser(block[crypt])
                          : crypt === 'deviceid'
                          ? <span className='deviceid' onClick={() => handleSelectDevice(block[crypt])}>{block[crypt]}</span>
                          : crypt === 'state'
                          ? setStateDevice(block[crypt])
                          : crypt === 'charge'
                          ? block[crypt]+'%' 
                          : block[crypt]
                          
                        }
                      </td>
                    ))}
                    {container === 'USUARIOS' && (
                      <td className='text-center'>
                        <ModalConstruct
                          modalTitle={'BORRAR USUARIO'}
                          buttonTitle={<TrashIcon />}
                          showModal={showModal}
                          setShowModal={setShowModal}
                          confClass={''}
                          addFn={() => handleShowModal(index, block['username'])}
                          >
                          <p key={index}>¿Está seguro que desea eliminar el usuario {username}?</p>
                          <button className='btn btn-light m-2' type='submit' onClick={() => { setShowModal(false); }}>
                            Cancelar
                          </button>
                          <button className='btn btn-danger' type='submit' onClick={() => { handleDelete(username); setShowModal(false); }}>
                            BORRAR
                          </button>
                        </ModalConstruct>
                      </td>
                    )}
                  </tr>
                  )) 
                  )}
              </tbody>
            </table>
          </div>
        </div>
        {pages !== 1 && (
          <div className='paginator'>
            <Pagination count={pages} size='small' color='primary' page={currentPage} onChange={handlePageChange} />
          </div>
        )}
      </div>
  );
}

/* timeout ficticio
setTimeout(() => {
  function();
}, 1000);
*/