import { backendInterceptor } from "./interceptors/CustomInterceptor";
import "bootstrap/dist/css/bootstrap.min.css"
import ReactDOM from 'react-dom/client';
import React from 'react';
import App from './App';
import './index.css';

backendInterceptor();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);
