
export function TransactionsFilter({ user, setUser, device, setDevice }) {
  return (
    <>
      <div className="form-group m-3">
        <label htmlFor="group">Dispositivo:</label>
        <input className='form-control' type="text" id="group" value={device} onChange={(e) => setDevice(e.target.value)} />
      </div>
      <div className="form-group m-3">
        <label htmlFor="group">Usuario:</label>
        <input className='form-control' type="text" id="group" value={user} onChange={(e) => setUser(e.target.value)} />
      </div>
    </>
    );
}

