import RenderContainer from './RenderContainer';
import  CreateUser  from '../Users/CreateUser';
import { FilterForm } from './Filters';



export default function Container({container, setContainer, setDevName, devNamme, setOnFilter, onFilter }) {
  const handleSelectedDevice = (deviceid) => {
    setDevName(deviceid);
    setContainer('DISPOSITIVO');
  }

  const handleUserCreated = () => {
    setContainer('DISPOSITIVOS');
  };

  return (
    <div className="container-fluid">
      {/** hoja de contenidos */}
      <div className="row">
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">{container === 'DISPOSITIVO' ? container + ' ' + devNamme.toLocaleUpperCase() : container}</h1>
            <div className="btn-group" role="group" aria-label="Grupo de botones">
              {container !== 'DISPOSITIVO' && <FilterForm container={container} setOnFilter={setOnFilter} />}
              {container === 'USUARIOS' && <CreateUser onUserCreated={() => handleUserCreated()} />}
            </div>
          </div>
          {/** contenidos */}
          <RenderContainer container={container} onSelectedDevice={(deviceid) => handleSelectedDevice(deviceid)} onFilter={onFilter} />
        </main>
      </div>
    </div>
  );

}