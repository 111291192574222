
export function DeviceFilter({ deviceid, setDeviceid, description, setDescription}) {
    return (
      <>
        <div className="form-group m-3">
          <label htmlFor="group">Dispositivo:</label>
          <input className='form-control' type="text" id="group" value={deviceid} onChange={(e) => setDeviceid(e.target.value)} />
        </div>
        <div className="form-group m-3">
          <label htmlFor="arg">Descripción:</label>
          <input className='form-control' type="text" id="arg" value={description} onChange={(e) => setDescription(e.target.value)} />
        </div>
      </>
      );
  }
  
  