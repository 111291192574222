import { useAuthContext } from '../../context/authContext';
import logo from '../../img/logo_blue2.svg';
import NavItems from './Navitems';
import { useState } from 'react'; 

export default function Header({ setContainer, handleReloadFilter}) {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const { logout, name } = useAuthContext();

    return (
        <header className="navbar navbar-dark sticky-top navbar-ferrimax flex-md-nowrap p-0">
        {/** botón de salir desplegable */}
        <div className="navbar-nav">
          <div className="nav-item text-nowrap" data-bs-toggle="dropdown" aria-expanded="false">
              <div className="user-item px-3 pb-1">{ name }
                <ul className="dropdown-menu logout-toggle" aria-labelledby="btnGroup">
                    <li><button className="logout-button dropdown-item" onClick={ logout }>Cerrar Sesión</button></li>
                </ul>
              </div>
          </div>
        </div>
        {/** botón responsive  */}
        <button 
          type="button" 
          aria-expanded="false" 
          data-bs-toggle="collapse" 
          aria-controls="sidebarMenu" 
          data-bs-target="#sidebarMenu" 
          aria-label="Toggle navigation"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="navbar-toggler position-absolute d-md-none collapsed" 
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {/** navegador */}
        <nav id="sidebarMenu" className={`col-md-3 col-lg-2 d-md-block bg-light sidebar ${isSidebarOpen ? 'collapse show' : 'collapse'}`}>
          {/** logo */}
          <a className="navbar-brand col-md-3 col-lg-2" href="https://ferrimax.com/es">
            <img src={logo} className="App-in-logo" alt="logo" />
          </a>
          <div className="nav-items">
            <NavItems setContainer={setContainer}  reloadFilter={() => handleReloadFilter()} setIsSidebarOpen={setIsSidebarOpen}/>
          </div>
        </nav>
      </header>
    );
}