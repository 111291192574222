
export function EventsFilter({group, setGroup, arg, setArg, name, setName}) {
  return (
    <>
      <div className="form-group m-3">
        <label htmlFor="group">Grupo:</label>
        <input className='form-control' type="text" id="group" value={group} onChange={(e) => setGroup(e.target.value)} />
      </div>
      <div className="form-group m-3">
        <label htmlFor="group">Nombre:</label>
        <input className='form-control' type="text" id="group" value={name} onChange={(e) => setName(e.target.value)} />
      </div>
      <div className="form-group m-3">
        <label htmlFor="arg">Argumento:</label>
        <input className='form-control' type="text" id="arg" value={arg} onChange={(e) => setArg(e.target.value)} />
      </div>
    </>
    );
}

