
export function ModalConstruct({ children, modalTitle, buttonTitle, showModal, setShowModal, confClass, addFn}) {

  if (confClass === undefined) {
    confClass = 'btn btn-light';
  }

  const handleToggleModal = () => {
    setShowModal(!showModal);
    if(addFn !== undefined){
      addFn();
    }
  };
  return (
    <>
      <span type="submit" className={confClass} onClick={() => handleToggleModal()}>
        {buttonTitle}
      </span>

      {showModal && (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="btn-close" onClick={handleToggleModal}>
                </button>
              </div>
              <div className="modal-body">
                {children}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}