import { ArrowLeftRight } from '../../icons/arrow_left-right';
import { PeopleFill } from '../../icons/people-fill';
import { Boxes } from '../../icons/boxes';
import { Event } from '../../icons/event';
import React, { useState } from 'react';

export default function NavItems({setContainer, reloadFilter, setIsSidebarOpen }) {
  const itemIcons = { 'DISPOSITIVOS': <Boxes />, 'EVENTOS': <Event />, 'TRANSACCIONES': <ArrowLeftRight />, 'USUARIOS': <PeopleFill /> };
  const items = ['DISPOSITIVOS', 'EVENTOS', 'TRANSACCIONES', 'USUARIOS', 'DISPOSITIVO'];
  const [selectedItem, setSelectedItem] = useState('DISPOSITIVOS');
  return(
    <ul className="nav flex-column">
      { 
        items.map((item, index) => (
           (item !== 'DISPOSITIVO') && (
            <li className="nav-item" key={index}>
                  <span className={`nav-link nav-button ${selectedItem === item ? 'selected' : ''}`} aria-current="page" onClick={() => { setContainer(item); reloadFilter(); setSelectedItem(item); setIsSidebarOpen(false);  }}>
                    {itemIcons[item]}{item}
                  </span>
            </li>)
          )
        )
      }
    </ul>
  );
}