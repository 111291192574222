//import { getValidationError } from "../utilities/get-validation-error";
import { SnackbarUtilities } from "../utilities/snackbar-manager";
import axios from "axios";

const api = axios.create({
  baseURL: 'https://intercambiador.ote-test.net/api/v1/', // URL base de la API
  timeout: 5000, // Tiempo máximo de espera para las solicitudes
});

export const backendInterceptor = () => {
  api.interceptors.request.use((config)=>{
    const timestamp = Date.now();
    config.params = {
      ...config.params,
      timestamp: timestamp
    }
    if (localStorage.getItem('token')) {
      config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    }
    return config;
  });
  api.interceptors.request.use((request)=> {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  });
  
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      //SnackbarUtilities.error(getValidationError(error.request.status));
      SnackbarUtilities.error(error.request.responseText);
      if (error.request.status === 401 && window.localStorage.getItem('token') !== null) {
        window.localStorage.removeItem('token');
        window.location.reload();
      }
      return Promise.reject(error);
    }
    );
  }
  
export default api;
