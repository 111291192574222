import { SnackbarUtilitiesConfigurator } from '../../utilities/snackbar-manager';
import React, { useState } from 'react';
import Container from './Container';
import Header from './Header';
import "./Dashboard.css";


function Dashboard() {
  const [devNamme, setDevName ] = useState('');
  const [onFilter, setOnFilter] = useState('');
  const [container, setContainer]= useState('DISPOSITIVOS');

  const handleReloadFilter = () => {
    setOnFilter('');
    setDevName('');
  }
  return ( 
    <>
      {/** Cabecera */}
      <Header setContainer={setContainer} handleReloadFilter ={handleReloadFilter}/>
      {/** Contenedor Principal */}
      <Container container={container} setContainer={setContainer} setDevName={setDevName} devNamme={devNamme} setOnFilter={setOnFilter} onFilter={onFilter}/>
      {/** utilidad de mensajes tipo snack */}
      <SnackbarUtilitiesConfigurator />
    </>
  );
}

export default Dashboard;