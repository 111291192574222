import { DEVI, EVEN, BOXS, TRAN, USER } from "../consts/constants";

export const route = (container) => {
  // eslint-disable-next-line
  switch (container) {
    case 'DISPOSITIVOS':
      return DEVI;
    case 'EVENTOS':
      return EVEN;
    case 'TRANSACCIONES':
      return TRAN;
    case 'USUARIOS':
      return USER;
    case 'DISPOSITIVO':
      return BOXS;
  }
}
export const arrayTitle = (container) => {
  // eslint-disable-next-line
  switch (container) {
    case 'DISPOSITIVOS':
      return ['DISPOSITIVO', 'DESCRIPCIÓN', 'FECHA DE CREACIÓN'];
    case 'EVENTOS':
      return ['GRUPO', 'NOMBRE', 'FECHA', 'ARGUMENTO I', 'ARGUMENTO II','ARGUMENTO III'];
    case 'TRANSACCIONES':
      return [ 'IDENTIFICADOR', 'DISPOSITIVO', 'USUARIO', 'FECHA', 'COMPARTIMENTO DE INGRESO', 'COMPARTIMENTO DE EGRESO', 'ESTADO'];
    case 'USUARIOS':
      return ['USUARIO', 'e-mail', 'ROL', 'FECHA DE CREACIÓN', ''];
    case 'DISPOSITIVO':
        return ['NÚMERO', 'ESTADO', 'TIPO', 'CARGA', 'FECHA'];
  }
}
export const arrayKey = (container) => {
  // eslint-disable-next-line
  switch (container) {
    case 'DISPOSITIVOS':
      return [ 'deviceid', 'description', 'date'];
    case 'EVENTOS':
      return ['group', 'name', 'date', 'arg1', 'arg2','arg3'];
    case 'TRANSACCIONES':
      return [ 'id', 'device', 'user', 'date', 'box1', 'box2', 'state'];
    case 'USUARIOS':
      return [ 'username', 'email', 'role', 'date' ];
    case 'DISPOSITIVO':
      return [ 'number', 'state', 'type', 'charge', 'date' ];
  }
}