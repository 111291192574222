import { TransactionsFilter } from '../Transactions/TransactionsFilter';
import { DeviceFilter } from '../Devices/DeviceFilter';
import { EventsFilter } from '../Events/EventsFilter';
import { UserFilter } from '../Users/UsersFillter';
import { ModalConstruct } from './Modal';
import React, { useState } from 'react';

export function FilterForm({ container, setOnFilter }) {
  // control del estado del modal
  const [showModal, setShowModal] = useState(false);

  const [since, setSince] = useState('');
  const [to, setTo] = useState('');
  // devices  
  const [deviceid, setDeviceid] = useState('');
  const [device, setDevice] = useState('');
  const [description, setDescription] = useState('');
  // events
  const [group, setGroup] = useState('');
  const [name, setName] = useState('');
  const [arg, setArg] = useState('');
  // transactions
  const [user, setUser] = useState('');
  // users
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [active, setActive] = useState('');
  const [role, setRole] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const filters = {
      ...(since !== '' && { since }),
      ...(to !== '' && { to }),
      // devices
      ...(device !== '' && { device }),
      ...(deviceid !== '' && { deviceid }),
      ...(description !== '' && { description }),
      // events
      ...(group !== '' && { group }),
      ...(name !== '' && { name }),
      ...(arg !== '' && { arg }),
      // transactions
      ...(user !== '' && { user }),
      // users
      ...(active !== '' && { active }),
      ...(email !== '' && { email }),
      ...(role !== '' && { role }),
      ...(username !== '' && { username }),

    };
    // Llamar a la función de filtro pasando los filtros como argumento
    setOnFilter(filters);
    // oculta el modal una vez realizada la tarea
    setShowModal(false);

  };
  return (
      <ModalConstruct modalTitle={'FILTROS'} buttonTitle={'Filtrar'} showModal={showModal} setShowModal={setShowModal}>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            {container === 'TRANSACCIONES' && (<TransactionsFilter user={user} setUser={setUser} device={device} setDevice={setDevice}/>)}
            {container === 'EVENTOS' && (<EventsFilter group={group} setGroup={setGroup} arg={arg} setArg={setArg} name={name} setName={setName}/>)}
            {container === 'DISPOSITIVOS' && (<DeviceFilter deviceid={deviceid} setDeviceid={setDeviceid} description={description} setDescription={setDescription} />)}
            {container === 'USUARIOS' && (<UserFilter username={username} setUsername={setUsername} role={role} setRole={setRole} email={email} setEmail={setEmail} active={active} setActive={setActive} />)}
            {/** COMMON */}
            <div className="form-group m-3">
              <label htmlFor="since">Inicio:</label>
              <input
                className='form-control'
                type="date"
                id="since"
                value={since}
                onChange={(e) => setSince(e.target.value)}
                />
            </div>
            <div className="form-group m-3">
              <label htmlFor="to">Final:</label>
              <input
                className='form-control'
                type="date"
                id="to"
                value={to}
                onChange={(e) => setTo(e.target.value)}
                />
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn btn-light" onClick={() => setShowModal(false)}>Cancelar</button>
            <button type="submit" className="btn btn-submit" data-bs-dismiss="modal">Filtrar</button>
          </div>
        </form>
      </ModalConstruct>
  );
}
