import { SnackbarUtilitiesConfigurator } from "../../utilities/snackbar-manager";
import { useAuthContext } from "../../context/authContext";
import { useState } from "react";
import Navbar from "./Navbar";

function Login() {
    const { login } = useAuthContext();
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    function handleSubmit(e) {
      e.preventDefault();
      login(user, password);
    }
    
    const userName = () => {
      let name = 'form-control text-center mt-1';
      if (!user) {
        name = 'ico-username ' + name;
      }
      return name;
    }
  
    const passwordName = () => {
      let name ='form-control text-center mt-1';
      if (!password) {
        name = 'ico-password ' + name;
      }
      return name;
    }
    return (
      <>
        <Navbar />
        <div className="Auth-form-container">
            <form className="Auth-form" onSubmit={handleSubmit}>
                <div className="Auth-form-content">
                  <h3 className="Auth-form-title">ADMIN INTERCAMBIADOR</h3>
                  <div className="form-group mt-3">
                    <label>
                        <input
                        type="text"
                        className={ userName() } 
                        placeholder="USUARIO"
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                        required="required"
                        />
                    </label>
                    <br />
                    <label>
                        <input
                        type="password"
                        className={ passwordName() }
                        placeholder="CONTRASEÑA"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required="required"
                        />
                    </label>
                    <div className="d-grid gap-2 mt-3">
                        <button className="btn btn-submit" type="submit">Iniciar Sesión</button>
                    </div>
                  </div>
                </div>
            </form>
            <SnackbarUtilitiesConfigurator />
        </div>
    </>
  );
}

export default Login;