
export function setData(params) {
    let data = {};
    if (params.role === '1' || params.role === '2') {
      data = {
        username: params.user,
        role: params.role*1,
        email: params.email
      }
    } else  {
      data = {
        username: params.user,
        role: params.role*1,
        password: params.password
      }
    }
    return data;
  }