import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { TryLogin, TryLogout } from "../services/Service";
import PropTypes from 'prop-types';

const TOKEN = 'token';
const NAME = 'name';

export const AuthContext = createContext();

export function AuthContextProvider({children}) {
  const [name, setName] = useState(
    window.localStorage.getItem(NAME) ?? ''
  );
  const [isAuth, setAuth] = useState(
    window.localStorage.getItem( TOKEN) ?? null
  );

    const login = useCallback(function (user, password) {
      const base64 = window.btoa(user + ':' + password);
      const resp = TryLogin({base64});
      resp.then( (value) => {
        if (typeof value !== 'undefined') {  
          window.localStorage.setItem( TOKEN, value);
          window.localStorage.setItem(NAME, user);
          setAuth(value);
          setName(user);
        }
      });
    }, []);

    const logout = useCallback(function () {
      const validToken = window.localStorage.getItem(TOKEN)
      TryLogout(validToken);
      window.localStorage.removeItem( TOKEN);
      window.localStorage.removeItem( NAME);
      setAuth(null);
    }, []);
    
    const value = useMemo(
      ()=> ({
        login,
        logout,
        isAuth,
        name
      }), 
      [ login, logout, isAuth, name]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthContextProvider.propTypes = {
    children: PropTypes.object
};

export function useAuthContext() {
  return useContext(AuthContext)
  
}