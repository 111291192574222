import { getValidationDelete } from "../utilities/get-validation-delete";
import { SnackbarUtilities } from "../utilities/snackbar-manager";
import { getValidationOk } from "../utilities/get-validation-ok";
import api from "../interceptors/CustomInterceptor";

export async function TryLogin(props) {
  try {
    const data = { role:1 };
    const headers = {
      'Authorization': 'Basic ' + props.base64,
      'Content-Type': 'application/json',
    };
    const response = await api.post('/sessions', data, { headers });
    return response.data.token;
  } catch (e) {
    //   console.log(e);
  }
}

export async function TrySearch(props) {
  try {
    const response = await api.get(props.search, { params: props.onFilter });
    return response.data;
  } catch (e) {
    ///console.log(e);
  }
}

export async function TrySearchBoxes(props) {
  try {
    const response = await api.get(`/devices/${props.deviceid}/boxes`);
    return response.data;
  } catch (e) {
    ///console.log(e);
  }
}

export async function TryCreateUser(props) {
  try {
    const response = await api.post(props.search, props.data);
    SnackbarUtilities.success(getValidationOk(response.status));
    return response;
  } catch (e) {
    ///console.log(e);
  }
}

export async function TryDeleteUser(username) {
  try {
    const response = await api.delete(`users/${username}`);
    SnackbarUtilities.warning(getValidationDelete(response.status));
    return response;

  } catch (e) {
    ///console.log(e);
  }
}

export async function TryLogout(validToken) {
  try {
    const headers = {
      'Authorization': 'Bearer ' + validToken,
      'Content-Type': 'application/json',
    }
    const response = await api.delete(`/sessions/${validToken}`, {headers});
    return response;
  } catch (e) {
   // console.log(e);
  }
}
